.cardMainlogin {
  margin: auto;
  background-color: white;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 8px;
  margin-top: 12% !important;
  margin-left: 15%;
  margin-right: 15%;
}

.loginformlabel {
  font-size: 13px;
  color: #000000b3;
  font-weight: 300 !important;
}
.loginform {
  margin-top: 2%;
}
.loginundelne {
  margin-top: 5%;
}

.contactpagebtn:hover {
  color: white;
  border-radius: 0px !important;
}
.contactpagebtn:active {
  border-radius: 0px !important;
}
.loginform .form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #e4e7ea !important;
  outline: 0;
  box-shadow: 0 0 0 0 #e4e7ea !important;
  border-radius: 0px !important;
}
.contactpagebtn:focus {


  outline: 0;
}

.loginformnames {
  font-size: 12px;
}
.formAlign1 {
  padding-right: 130px;
}
.pswd-lgn-icon {
  left: 20px !important;
  width: 0px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .cardMainlogin {
    width: 40rem;
    margin-top: 12% !important;
    margin-left: 0%;
    margin-right: 0%;
  }
}
@media (max-width: 767px) and (min-width: 550px) {
  .cardMainlogin {
    width: 30rem;

    margin-top: 15% !important;
    margin-left: 0%;
    margin-right: 0%;
  }
  .loginimage {
    width: 81%;
    margin-top: 15%;
    margin-bottom: 0%;
    margin-left: 20px;
  }
  .loginheaderfrst {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (max-width: 549px) and (min-width: 300px) {
  .loginheaderfrst {
    margin-left: 10%;
    margin-right: 10%;
  }
  .loginimage {
    width: 100%;
    margin-top: 15%;
    margin-bottom: 0%;
    margin-left: 0px;
  }
  .cardMainlogin {
    margin-top: 15% !important;
    margin-left: 0%;
    margin-right: 0%;
  }
}
@media only screen and (max-width: 768px) {
  .backgroundsecscn {
    height: 450px !important;
  }
  .loginseondsection {
    margin-left: 8% !important;
    margin-top: 0%;
  }
}

.docdroppera {
  color: white;
  font-size: 14px;
  width: 55%;
  margin-left: 55px;
  margin-bottom: 5px;
}
.loginform .form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #e4e7ea;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 55px;
  height: 40px;
}
.loginform .form-group {
  margin-bottom: 15px;
}
.remembermeccheck {
  color: white;
  margin-left: 55px;
}
.loginform .form-check-label {
  margin-bottom: 0;
  margin-left: 10px !important;
  margin-top: 5px !important;
}
.loginforgot {
  font-size: 11px;
  color: fromRGBO(0, 44, 57, 1);

  color: #063e4d;
  margin-left: 60px;
  font-weight: 500 !important;
  cursor: pointer;
}
.contactpagebtn {
  width: 100%;
  margin-top: 15px;
  padding-top: 7px;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: white;
  background-color:  #063e4d;

  border: 1px solid  #063e4d !important;
  border-radius: 0px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 55px;
}
.loginheader {
  font-size: 24px;
  color: white;

  font-weight: 600 !important;
  margin-left: 55px;
  margin-bottom: -15px;
}
.loginimage {
  width: 30%;
  margin-top: 10%;
  margin-bottom: 3%;
  margin-left: 55px;
}
.backgroundscreen {
  background-image: linear-gradient(to bottom, #0000004f, rgba(0, 0, 0, 0.51)),
    url("../../assets/img/loginimag.png") !important;
  float: none;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100vw;
  min-height: 100vh;

  background-position: center center;
  background-size: cover;
}
.footerclass {
  margin-top: 35px;
  margin-bottom: 18px;
  color: white;
  margin-left: 7%;
}

@media (max-width: 1645px){
  .footerVersion{
    margin-top: 70px;
  }
}
@media (max-width: 1245px){
  .footerVersion{
    margin-top: 130px;
  }
}
@media (max-width: 768px){
  .footerVersion{
    margin-top: 0px;
  }
  .footerclass {
    /* margin-top: 20%; */
    /* margin-bottom: 18px; */
    color: black;
    margin-left: 7%;
  }
}
/* @media (max-width: 560px){
  .footerclass {
    margin-top: 30%;
  }
} */

.loginform .label-inpt {
  font-size: 16px !important;
  color: white;
  margin-left: 55px;
}
.loginform .errStyle {
  color: red;
  font-size: 12px;
  margin-left: 55px;
}

/* ========================newlogin============================= */
.backgroundsecscn {
  background-color: white;
  /* margin-top: 10%; */
  height: 377px;
}
.bgcolorLogin {
  background-color: #063e4d;
  height: 55px;
  margin-top: 7%;
  opacity: 0.4;
}
@media (max-width: 768px){
  .bgcolorLogin{
    margin-top: 0%;
  }
  .logintitle {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    margin-bottom: 0px !important;
    color: fromRGBO(0, 44, 57, 1);
    /* color: #3acc61 !important; */
  }
  .logincontent12 {
    background-color: #063e4d;
    height: 0px;
  }
  .loginseondsection {
    margin-left: 10%;
    margin-top: 0%;
  }
  .loginimages {
    width: 10%;
  }
  .forgot-title{
    font-size: 13px;
  }
  .infoText{
    font-size: 10px;
  }
  .loginfooterimages {
    width: 25%;
    display: flex;
    margin: auto;
  }
}
.logincontent12 {
  background-color: #063e4d;
  height: 50px;
}
@media (min-width: 768px){
  .loginseondsection {
    margin-left: 10%;
    margin-top: 5%;
  }
  .loginimages {
    width: 20%;
  }
  .logintitle {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 30px !important;
    margin-bottom: 20px !important;
    color: fromRGBO(0, 44, 57, 1);
    /* color: #3acc61 !important; */
  }
  .loginfooterimages {
    width: 40%;
    display: flex;
    margin: auto;
  }
  .infoText{
    font-size: 12px;
  }
}
@media(min-width : 2000px) {
  .loginseondsection {
    margin-left: 10%;
    margin-top: 0%;
  }

}



.forgotpassDiv{
  display: flex;
  justify-content: flex-end;
}
.resetforgot {
  display: flex;
  font-size: 12px;
  justify-content: right;
  color: #063e4d;
  margin-right: -33px;
  font-weight: 500 !important;
  cursor: pointer;
}